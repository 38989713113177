<script>
</script>

<div class="stamps">
	<img class="CFCC" src="/images/pledge/CFCC.svg" alt="CFCC" />
	<img class="PEFC" src="/images/pledge/PEFC.svg" alt="PEFC" />
	<img class="FSC" src="/images/pledge/FSC.svg" alt="FSC" />
	<img class="FFA" src="/images/pledge/FFA.svg" alt="FFA" />
</div>

<style>
	.stamps {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	img {
		position: relative;
	}
	.CFCC {
		width: 46%;
		top: 4px;
		left: -12px;
		transform: rotate(-10deg) scale(1);
	}
	.PEFC {
		width: 40%;
		top: 10px;
		left: -14px;
		transform: rotate(4deg) scale(1);
	}
	.FSC {
		width: 40%;
		top: -45px;
		left: 48px;
		transform: rotate(-3deg) scale(1);
	}
	.FFA {
		width: 48%;
		top: -48px;
		left: 18px;
		transform: rotate(4deg) scale(1);
	}
</style>
