<script>
	import { fly } from "svelte/transition"
	export let trigger
</script>

<div class="pill">
	<div class="wrap">
		{#if trigger}
			<div class="group" in:fly={{ x: -200, duration: 500 }}>
				<img src="/images/pills/dots_1.png" alt="" />
				<h5>Absorbent dotted plies</h5>
			</div>
			<div class="group" in:fly={{ x: -400, duration: 500, delay: 300 }}>
				<img src="/images/pills/no_chemicals_3.png" alt="" />
				<h5>No added optical brightner</h5>
			</div>
			<div class="group" in:fly={{ x: -400, duration: 500, delay: 600 }}>
				<img src="/images/pills/virgin_pulp_3.png" alt="" />
				<h5>100% virgin pulp</h5>
			</div>
		{/if}
		<button class:trigger={!trigger}><a href="#wheretobuy">Buy Today</a> </button>
	</div>
</div>

<style>
	.pill {
		font-family: "Nunito";
		font-style: normal;
		text-align: left;
		color: #ffffff;
		position: relative;
		z-index: 5;
	}
	.wrap {
		max-width: 1200px;
		margin: 0 auto;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		box-sizing: content-box;
		border: 1px solid #ffffff;
		border-radius: 54px;
		filter: drop-shadow(0px 4px 27px rgba(0, 23, 107, 0.25));
	}
	.group {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	h5 {
		font-weight: 900;
		font-size: calc(0.8 * 24px);
		width: 158px;
	}
	button {
		background: #ffffff;
		box-shadow: 0px 4px 27px rgba(0, 23, 107, 0.25);
		border-radius: 54px;
		width: 250px;
		/* flex-basis: 250px; */
		height: 90px;
		font-weight: 900;
		font-size: calc(0.8 * 32px);
		margin: 0;
	}
	button > a {
		color: #5d70d7;
	}
	button:hover {
		font-size: calc(0.9 * 32px);
	}
	.trigger {
		margin-left: auto;
	}

	@media (max-width: 1150px) {
		.pill {
			padding: 0 10px;
		}
		.wrap {
			flex-wrap: wrap;
			justify-content: space-around;
			gap: 40px;
			margin: 0 auto;
		}
	}
	@media (max-width: 890px) {
		.wrap {
			flex-direction: column;
			max-width: 500px;
		}
	}
</style>
