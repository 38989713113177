import { derived, writable } from "svelte/store"
import stores from "./locations"

export const DEFAULT_CENTER = {
	lat: 33.9883677,
	lng: -118.1939286,
}

export const MyLocationStore = writable(DEFAULT_CENTER)

export const StoresStore = writable(stores)

export const SelectedStore = writable(1)

export const MarkerStore = derived(StoresStore, stores => {
	return stores.map(store => {
		const { lat, lng } = store
		return new google.maps.Marker({
			position: { lat, lng },
			title: store.name,
			label: String(store.marker),
			animation: google.maps.Animation.DROP,
		})
	})
})
