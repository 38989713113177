<script>
	import Geolocation from "svelte-geolocation"
	import { createEventDispatcher } from "svelte"
	import { MyLocationStore } from "./MapStore"

	const dispatch = createEventDispatcher()
	let coords // lng:[0], lat:[1]
	let getPosition = false
	let geo
	// let location = {lat:null, lng:null}

	function handleClick() {
		dispatch("gotGeo")
		// console.log("clicked")
		document.getElementById("map-container").scrollIntoView({ block: "start", behavior: "smooth" })
		//first time
		if (coords[0] === -1) {
			getPosition = true
		} else {
			// next time
			MyLocationStore.update(() => {
				return { lat: coords[1], lng: coords[0] }
			})
		}
	}

	function onPosition() {
		// console.log(coords)
		MyLocationStore.update(() => {
			return { lat: coords[1], lng: coords[0] }
		})
	}
</script>

<div class="get">
	<button on:click={handleClick}>
		<i class="fa-sharp fa-solid fa-location-crosshairs" /> Use My Location
	</button>

	<Geolocation {getPosition} bind:this={geo} bind:coords let:loading let:success let:error let:notSupported on:position={onPosition}>
		{#if notSupported}
			Your browser does not support the Geolocation API.
		{:else}
			{#if loading}
				Loading...
			{/if}
			{#if success}
				<!-- {JSON.stringify(coords)} -->
			{/if}
			{#if error}
				An error occurred. {error.code} {error.message}
			{/if}
		{/if}
	</Geolocation>
</div>

<style>
	button {
		margin-left: -60px;
		box-sizing: border-box;
		width: 374px;
		height: 100%;
		border-radius: 50px;
		font-family: "Nunito";
		font-weight: 900;
		font-size: 26px;
		color: #fff;
		text-align: center;
		background-color: var(--indigo);
		cursor: pointer;
	}
	i {
		padding-right: 10px;
	}
	@media (max-width: 890px) {
		button {
			/* margin-left: 0; */
			padding: 0 15px;
			width: 100%;
			height: 60px;
			margin: 20px 0;
		}
	}
</style>
