<script>
	import { onMount } from "svelte"
	let swiper

	// onMount(() => {
	// 	window.onload = () => {
	// 	swiper = new Swiper(".mySwiper", {
	// 		navigation: {
	// 			nextEl: ".swiper-button-next",
	// 			prevEl: ".swiper-button-prev",
	// 		},
	// 		pagination: {
	// 			el: ".swiper-pagination",
	// 		},
	// 	})
	// 	}
	// })
</script>

<div class="swiper mySwiper">
	<div class="swiper-wrapper">
		<div class="swiper-slide">
			<img srcset="/images/products/paper_towels_2.webp, /images/products/paper_towels_2.png" alt="paper towels" />
		</div>
	</div>
	<div class="swiper-button-next" />
	<div class="swiper-button-prev" />
	<div class="swiper-pagination" />
</div>

<style>
	img {
		width: 100%;
		object-fit: contain;
		max-height: 67vh;
	}
	.swiper {
		width: 100%;
		max-width: 100%;
		min-width: 0;
		min-height: 0;
		padding-bottom: 10px;
	}
	.swiper-button-next,
	.swiper-button-prev {
		color: white;
		scale: 0.5;
		width: 100px;
		height: 100px;
		top: 50%;
		translate: 0 -50%;
		transition: 0.2s;
		border-radius: 50px;
		place-items: center;
	}
	.swiper-button-prev {
		left: -30px;
	}
	.swiper-button-next {
		right: -30px;
	}
	.swiper-button-next:hover,
	.swiper-button-prev:hover {
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	}

	:global(.swiper-button-disabled) {
		opacity: 0 !important;
	}

	:global(.swiper-pagination-bullet-active) {
		background: #808ad9 !important;
	}
</style>
