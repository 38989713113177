<script>
	import ToiletPaperGallery from "./ToiletPaperGallery.svelte"
</script>

<div class="tp-slide">
	<div class="wrap">
		<ToiletPaperGallery />
		<div class="content">
			<h3>12 PACK</h3>
			<h1>Soft & Strong Big Rolls</h1>
			<div class="highlight">
				<h3>12 ROLLS - 400 SHEETS</h3>
			</div>
			<p>Soft, strong premium bath tissue that gives you greater value on consumer spending than most leading brands. Without the lint!</p>
		</div>
	</div>
</div>

<style>
	.tp-slide {
		font-family: "Nunito";
		font-style: normal;
		text-align: center;
		color: #ffffff;
	}
	.wrap {
		display: flex;
		padding: 0 15vw;
		justify-content: center;
		align-items: center;
		gap: 50px;
	}

	.content {
		padding: 50px 0;
		width: 485px;
		display: flex;
		flex-direction: column;
		gap: 25px;
		justify-content: center;
		flex-shrink: 0;
	}

	h1 {
		font-weight: 900;
		font-size: calc(0.8 * 72px);
		text-shadow: 0px 4px 4px rgba(46, 49, 146, 0.53);
		line-height: 100%;
	}
	h3 {
		font-weight: 900;
		font-size: calc(0.8 * 32px);
	}
	p {
		font-weight: 600;
		font-size: calc(0.8 * 28px);
		line-height: 136.9%;
	}
	.highlight {
		width: 432px;
		height: 58px;
		background: #808ad9;
		border-radius: 54px;
		margin: 10px auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 1150px) {
		.wrap {
			padding: 0 5vw;
		}
	}

	@media (max-width: 890px) {
		.wrap {
			flex-direction: column;
			padding: 0 10px;
			gap: 20px;
		}
		.content {
			width: auto;
			margin-bottom: 50px;
			max-width: 600px;
			align-items: center;
			padding: 0 20px;
		}
		.highlight {
			width: 100%;
			/* padding: 0 20px; */
		}
		h1 {
			max-width: 580px;
			font-size: 12vw;
		}
		h3 {
			font-size: 5vw;
		}
		p {
			font-size: 16px;
		}
	}
</style>
