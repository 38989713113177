<script>
	import { onMount } from "svelte"
	import { MyLocationStore } from "./MapStore"

	let autoInput
	let autocomplete

	export function clearAddress() {
		autoInput.value = ""
	}

	function handleComplete() {
		let bar = autocomplete.getPlace()
		MyLocationStore.update(() => {
			return {
				lat: bar.geometry.location.lat(),
				lng: bar.geometry.location.lng(),
			}
		})
		document.getElementById("map-container").scrollIntoView({ block: "start", behavior: "smooth" })
	}

	onMount(async () => {
		autocomplete = new google.maps.places.Autocomplete(autoInput, {
			// autocomplete = new google.maps.places.SearchBox(autoInput, {
			types: ["geocode"],
			componentRestrictions: { country: ["us"] },
			fields: ["place_id", "geometry", "formatted_address"],
		})
		autocomplete.addListener("place_changed", handleComplete)
	})
</script>

<input class="autoInput" bind:this={autoInput} placeholder={"Type any state, city or street name"} type="text" />

<style>
	.autoInput {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border-radius: 50px;
		font-family: "Nunito";
		font-weight: 600;
		font-size: 22px;
		padding: 0 40px;
		box-shadow: 0px 4px 27px rgba(0, 23, 107, 0.25);
	}

	@media (max-width: 890px) {
		.autoInput {
			height: 60px;
		}
	}
</style>
