<script>
	let scrollY, innerHeight, innerWidth
	let tpWidth, canvasHeight, canvas

	$: rollHeight = tpWidth * 0.7
	// $: unrolled = innerHeight * canvases

	$: unrolled = document.getElementById("footer")?.offsetTop - canvas?.offsetTop + scrollY * 0
	$: height = scrollY > canvas?.offsetTop ? unrolled : rollHeight
	$: canvasY = scrollY - canvas?.offsetTop
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />

{#if innerWidth > 890}
	<div class="roll" bind:this={canvas} bind:clientHeight={canvasHeight}>
		<div class="left" />

		<div class="right">
			<div class="tp-container" bind:clientWidth={tpWidth} style="height:{height}px">
				<div class="tp-squares" />
				{#if canvasY + innerHeight * 1.5 < unrolled}
					<img style="bottom: -{rollHeight / 1.8}px" alt="TP Roll" src="/images/tp/TP-Roll-Dark.webp" />
				{/if}
			</div>
		</div>
	</div>
{:else if innerWidth}
	<div class="roll" bind:this={canvas} bind:clientHeight={canvasHeight}>
		<div class="left" />

		<div class="right">
			<div class="tp-container" bind:clientWidth={tpWidth} style="height:{unrolled}px">
				<div class="tp-squares" />
			</div>
		</div>
	</div>
{/if}

<style>
	.roll {
		height: 100vh;
		padding: 0 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 0px;
	}
	.right {
		position: relative;
	}
	.tp-container {
		/* background-color: red; */
		z-index: 5;
		position: absolute;
		top: 0;
		width: 100%;
		transition: height 2s ease-in-out;
	}
	.tp-squares {
		/* background-image: url("/images/tp/TP-Squares.png"); */
		background-image: url("/images/tp/TP-Squares.webp");
		background-size: 100%;
		height: 100%;
		overflow-y: hidden;
	}
	.tp-container > img {
		width: 110%;
		position: absolute;
		right: 0;
		z-index: 15;
		margin-right: -1px;
		/* opacity: 0.2; */
	}

	@media (max-width: 890px) {
		.roll {
			display: block;
			padding: 0 10px;
			height: 10vh;
		}
		.tp-container {
			z-index: 1;
		}
		.tp-container > img {
			width: calc(100% + 25px);
		}
	}
</style>
