<script>
	export let state
</script>

<div class="tab-bar">
	<h3>Discover Our Products</h3>
	{#if state !== "MOBILE"}
		<div class="tabs">
			<h2 class:active={state === "TP"}>TOILET PAPER</h2>
			<h2 class:active={state === "PT"}>PAPER TOWELS</h2>
		</div>
	{/if}
</div>

<style>
	.tab-bar {
		width: 100%;
		height: 90px;
		padding: 0 50px;
		background: rgba(46, 49, 146, 0.3);
		background: rgba(46, 49, 146);
		font-family: "Nunito";
		font-style: normal;
		text-align: center;
		display: flex;
		justify-content: space-around;
		align-items: center;
		font-size: 25px;
	}
	.tabs {
		display: flex;
		gap: 50px;
		flex-basis: 50%;
		justify-content: center;
	}
	h3 {
		font-weight: 900;
		color: #b5b7ff;
		font-size: 25px;
		/* margin-left: 100px; */
		flex-basis: 60%;
	}
	h2 {
		font-weight: 900;
		text-align: center;
		color: rgba(255, 255, 255, 0.6);
		font-size: 25px;
	}
	h2.active {
		font-weight: 900;
		color: #ffffff;
		font-size: 25px;

		text-decoration: underline;
	}
	@media (max-width: 890px) {
		.tab-bar {
			padding: 0;
			margin-bottom: 10px;
		}
	}
</style>
