<script>
	import Footer from "./components/Footer.svelte"
	import BlueBG from "./components/Background/BlueBG.svelte"
	import HeroCanvas from "./components/HeroCanvas.svelte"
	import Header from "./components/Header/Header.svelte"
	import Products from "./components/Products/Products.svelte"
	import WhereToBuyCanvas from "./components/WhereToBuy/WhereToBuyCanvas.svelte"
	import ContactCanvas from "./components/ContactCanvas.svelte"
	import RollCanvas from "./components/About/RollCanvas.svelte"
	import MaximumCanvas from "./components/MaximumCanvas.svelte"
	import GlanceCanvas from "./components/About/GlanceCanvas.svelte"
	import GreenCanvas from "./components/About/GreenCanvas.svelte"
	import GlanceMobile from "./components/About/GlanceMobile.svelte"
	import GreenMobile from "./components/About/GreenMobile.svelte"
	import HeaderMobile from "./components/Header/HeaderMobile.svelte"

	export let ready // main.js
	let innerWidth
	let disabled = true
</script>

<svelte:head>
	<script defer async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCBeNUvphqB7GqY83NENviqB2FRclsThek&libraries=places,geometry&callback=initMap"></script>
	<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&family=Russo+One&display=swap" rel="stylesheet" />
</svelte:head>
<svelte:window bind:innerWidth />

<main>
	{#if innerWidth > 890}
		<Header {disabled} />
	{:else if innerWidth}
		<HeaderMobile {disabled} />
	{/if}
	<BlueBG>
		<HeroCanvas on:unlock={() => (disabled = false)} />
		{#if !disabled}
			<Products />
			<MaximumCanvas />
			<RollCanvas />
			{#if innerWidth > 890}
				<GlanceCanvas />
			{:else}
				<GlanceMobile />
			{/if}
		{/if}
	</BlueBG>
	{#if !disabled}
		{#if innerWidth > 890}
			<GreenCanvas />
		{:else if innerWidth}
			<GreenMobile />
		{/if}
		<WhereToBuyCanvas {ready} />
		<ContactCanvas />
		<Footer />
	{/if}
</main>

<style>
	:global(html *) {
		box-sizing: border-box;
	}
	main {
		scroll-behavior: smooth;
		/* overflow: hidden; */
		background: linear-gradient(105.04deg, #263c8a -10.04%, #3045b8 91.26%);
	}
</style>
