<script>
	import { fly, slide } from "svelte/transition"
	import PaperTowelPill from "./PaperTowelPill.svelte"
	import PaperTowelCanvas from "./PaperTowelCanvas.svelte"
	import TabBar from "./TabBar.svelte"
	import ToiletPaperPill from "./ToiletPaperPill.svelte"
	import ToiletPaperCanvas from "./ToiletPaperCanvas.svelte"
	let scrollY, innerHeight, innerWidth, canvas
	$: canvasY = scrollY - canvas?.offsetTop
	$: state = canvasY - 200 < innerHeight / 2 ? "TP" : "PT"
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
{#if innerWidth < 1150}
	<div class="products mobile" id="products">
		<TabBar state="MOBILE" />
		<ToiletPaperCanvas />
		<ToiletPaperPill trigger={true} />
		<PaperTowelCanvas />
		<PaperTowelPill trigger={true} />
	</div>
{:else if innerWidth}
	<div class="products" id="products" bind:this={canvas}>
		<div class="sticky" style="top:0" transition:slide>
			<TabBar {state} />
		</div>
		<div class="container">
			<ToiletPaperCanvas />
			<ToiletPaperPill trigger={canvasY > innerHeight * -0.2} />
		</div>
		<div class="container">
			<PaperTowelCanvas />
			<PaperTowelPill trigger={state === "PT"} />
		</div>
	</div>
{/if}

<style>
	.products {
		min-height: 200vh;
		position: relative;
		z-index: 10;
	}
	.mobile {
		height: auto;
	}
	.sticky {
		position: sticky;
		z-index: 7;
	}
	.container {
		width: 100%;
		/* height: 90vh; */
		min-height: 90vh;
	}
</style>
