<script>
	import { StoresStore, SelectedStore } from "./MapStore"

	let list = []
	let listClick = false

	SelectedStore.subscribe(selected => {
		if (!list.length) return
		if (listClick) {
			listClick = false
			return
		}
		let selectedEl = list[selected]
		selectedEl.scrollIntoView({ block: "nearest", behavior: "smooth" })
	})

	function clickHandler(marker) {
		listClick = true
		SelectedStore.update(() => marker)
	}
</script>

<div class="store-list">
	<!-- <div>{$SelectedStore}</div> -->
	{#each $StoresStore as store, i}
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div class="card" class:selected={$SelectedStore === store.marker} bind:this={list[i + 1]} on:click={() => clickHandler(i + 1)}>
			<div class="icon">
				<img class="pin" src="/images/map/maxie_pin.png" alt="maxie_pin" />
			</div>
			<div class="info">
				<h2>{store.marker ? store.marker + "." : ""} {store.name}</h2>
				<!-- <p>{store.place_id}</p> -->
				<p>{store.location}</p>
				<p>{store.hours}</p>
			</div>
			<div class="driving">
				<a href="https://www.google.com/maps/place/?q=place_id:{store.place_id}" target="_blank" rel="noreferrer">
					<button>Get directions</button>
				</a>
				<span>{store.durationText ? store.durationText + " • " : ""} {store.distanceText || ""}</span>
				<!-- <span>{store?.durationText || ""}</span> -->
				<!-- <span>{store.distanceText || ""}</span> -->
			</div>
		</div>
	{/each}
</div>

<style>
	.store-list {
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.card {
		display: flex;
		scroll-margin: 5px;
		border-bottom: 1px solid var(--indigo);
		padding: 10px;
	}
	p {
		margin: 0;
	}
	.selected {
		background-color: lightblue;
	}
	.pin {
		margin: 0 10px 0 0;
	}
	.driving {
		margin-left: auto;
		margin-right: 5px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	h2 {
		font-family: "Nunito";
		font-weight: 900;
		font-size: calc(36px * 0.8);
		color: var(--indigo);
		margin: 0;
	}
	button {
		/* margin-left: -60px; */
		padding: 5px 20px;
		/* box-sizing: border-box; */
		/* width: 374px; */
		/* height: 100%; */
		border-radius: 50px;
		font-family: "Nunito";
		font-weight: 900;
		/* font-size: 26px; */
		color: #fff;
		text-align: center;
		background-color: var(--indigo);
		cursor: pointer;
	}

	@media (max-width: 890px) {
		.card {
			/* flex-wrap: wrap; */
			flex-direction: column;
			justify-content: center;
			text-align: center;
		}
		.driving {
			margin: 10px 0;
		}
		.pin {
			display: none;
		}
	}
</style>
