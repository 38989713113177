<script>
	import Stamps from "./Stamps.svelte"

	let scrollY, innerHeight, innerWidth, canvas

	$: canvasY = scrollY - canvas?.offsetTop
	$: canvasYTitle = scrollY - canvas?.offsetTop + 600
	$: canvasYCircle = scrollY - canvas?.offsetTop + innerHeight * 2

	$: size = 142 * (canvasYCircle / innerHeight) * (canvasYCircle / innerHeight) * (canvasYCircle / innerHeight)
	$: titleTop = 233 * (canvasYTitle / 400) * (canvasYTitle / 400) * (canvasYTitle / 400) - 233
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<div class="green-canvas" bind:this={canvas}>
	<div class="circle" class:hidden={canvasYCircle > innerHeight * 2 || canvasYCircle < 0} style="width: clamp(0px, {size}vmax, 142vmax); height: clamp(0px, {size}vmax, 142vmax)" />
	<div class="grid">
		<div class="left">
			<div class="text">
				<p>
					Outside of our mission to keep you comfortable indoors, Maxie believes that product sustainability is the cornerstone for any company’s credibility. Maxie has adopted a
					<br /><strong>low-carbon, green supply chain methodology.</strong>
					<br /><br />
					With sustainability in mind, Maxie takes pride in caring not only for our beloved customers, but also for our planet. 🌎
				</p>
			</div>
		</div>
		<div class="right">
			<div class="right-container">
				{#if canvasY < 0}
					<div class="title title-fixed" style="top: clamp(-250px, {titleTop}px, 0px)">MAXIE<sup>Ⓡ</sup><br />Cares</div>
				{:else if canvasY < innerHeight * 1.7}
					<div class="title title-sticky">MAXIE<sup>Ⓡ</sup><br />Cares</div>
					<Stamps />
				{/if}
			</div>
		</div>
	</div>
</div>

<style>
	.green-canvas {
		height: 200vh;
		background: linear-gradient(to right, #85c196, #16a491);
		color: white;
	}
	.grid {
		padding: 0 30px;
		height: 200vh;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 0px;
		/* grid-template-rows: "left right"; */

		/* text-shadow: 0px 4px 10px #2e3192; */
	}
	.left {
		/* grid-area: left; */
		height: 170vh;
		z-index: 10;
	}
	.right {
		/* grid-area: right; */
		z-index: 10;
		height: 170vh;
	}
	.circle {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 200px;
		height: 200px;
		background: linear-gradient(to right, #85c196, #16a491);
		border-radius: 100vmax;
		z-index: 2;
	}
	.hidden {
		visibility: hidden;
	}
	.text {
		display: flex;
		justify-content: center;
		align-items: center;
		position: sticky;
		top: 0;
		height: 100vh;
		font-size: calc(1 * 24px);
	}
	p {
		flex-basis: 60%;
	}
	.right-container {
		height: 100vh;
		display: flex;
		align-items: center;
		position: sticky;
		top: 0;
		flex-direction: column;
	}
	.title {
		padding: 50px 30px 0px;
		font-weight: 900;
		font-size: 90px;
		line-height: 102.4%;
		text-align: center;
		color: green;
		text-shadow: 1px 3px 0 #bbb, 1px 13px 5px #ccc;
	}
	.title-fixed {
		position: fixed;
	}
	strong {
		font-weight: 900;
	}
	sup {
		vertical-align: text-top;
		font-size: 25px;
	}
</style>
