<script>
	import { DEFAULT_CENTER, MyLocationStore, SelectedStore, MarkerStore } from "./MapStore"
	import { setMapLocation, updateSort } from "./mapFunctions"
	import { onDestroy, onMount } from "svelte"
	import { get } from "svelte/store"

	let mapElement
	let map = null
	let myMarker
	let oldMarkers = []

	const subMyLocation = MyLocationStore.subscribe(location => {
		if (location.lat === DEFAULT_CENTER.lat) return
		if (myMarker) myMarker.setMap(null) // remove if prev
		myMarker = setMapLocation(location, map)
		updateSort(location)
	})

	const subMarkers = MarkerStore.subscribe(markers => {
		if (!map) return
		oldMarkers.forEach(old => old.setMap(null))
		markers.forEach((marker, index) => {
			window.setTimeout(() => marker.setMap(map), index * 20)
			marker.addListener("click", () => {
				SelectedStore.update(() => index + 1)
			})
		})
		oldMarkers = markers
	})

	const subSelected = SelectedStore.subscribe(selected => {
		if (!map) return
		let markers = get(MarkerStore)
		let marker = markers[selected - 1]
		marker.setMap(null)
		marker.setAnimation(google.maps.Animation.DROP)
		marker.setMap(map)
	})

	onMount(async () => {
		map = new google.maps.Map(mapElement, {
			zoom: 11,
			center: DEFAULT_CENTER,
			// styles: mapStyles
		})
		// console.log("new map")
		// updateSort(DEFAULT_CENTER)
	})

	onDestroy(() => {
		subMyLocation
		subMarkers
		subSelected
	})
</script>

<div class="mapContainer" id="map-container">
	<div class="full" id="map" bind:this={mapElement} />
</div>

<style>
	.mapContainer {
		height: 100%;
		width: 100%;
		padding: 0 10px;
		/* background-color: lightgreen; */
		position: relative;
		z-index: 10;
	}
	.full {
		width: 100%;
		height: 100%;
	}
	#map-container {
		scroll-margin-top: 130px;
	}
</style>
