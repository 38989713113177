<script>
	import { fly, slide } from "svelte/transition"

	let scrollY, innerHeight, innerWidth, canvas, box
	let boxHeight = 0
	$: canvasY = scrollY - canvas?.offsetTop

	$: pillsTop = 60 + (canvasY / (innerHeight * 2)) * -10
	$: titleTop = 60 + (canvasY / (innerHeight * 2)) * -30

	function addSpace() {
		boxHeight = boxHeight + 120
	}

	function removeSpace() {
		boxHeight = boxHeight - 120
	}
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<div class="glance" bind:this={canvas}>
	{#if canvasY + innerHeight > 0}
		<div class="right" style="height: calc(100% - {boxHeight}px)">
			<div class="title" style="top:{titleTop}vh" transition:slide={{ delay: 500 }}>About<br />MAXIE<sup>Ⓡ</sup></div>
		</div>
	{/if}
	<div class="left">
		<div class="glance-pills" bind:this={box} style="top: {pillsTop}vh; height: {boxHeight}px">
			{#if canvasY + innerHeight > innerHeight * 0}
				<div class="icon-group" transition:fly={{ y: 200, duration: 1000 }} on:introstart={addSpace} on:outrostart={removeSpace}>
					<img src="/images/about/about_icon-17.png" alt="" />
					<span>No added Optical Brightener or Fluorescent Additives</span>
				</div>
			{/if}
			{#if canvasY + innerHeight > innerHeight * 0.5}
				<div class="icon-group" transition:fly={{ y: 200, duration: 1000 }} on:introstart={addSpace} on:outrostart={removeSpace}>
					<img src="/images/about/about_icon-18.png" alt="" />
					<span>Ultra Soft and Strong</span>
				</div>
			{/if}
			{#if canvasY + innerHeight > innerHeight * 1}
				<div class="icon-group" transition:fly={{ y: 200, duration: 1000 }} on:introstart={addSpace} on:outrostart={removeSpace}>
					<img src="/images/about/about_icon-19.png" alt="" />
					<span>Ultra High Temp Sterilized</span>
				</div>
			{/if}
			{#if canvasY + innerHeight > innerHeight * 1.5}
				<div class="icon-group" transition:fly={{ y: 200, duration: 1000 }} on:introstart={addSpace} on:outrostart={removeSpace}>
					<img src="/images/about/about_icon-20.png" alt="" />
					<span>100% Virgin Pulp</span>
				</div>
			{/if}
			{#if canvasY + innerHeight > innerHeight * 2}
				<div class="icon-group" transition:fly={{ y: 200, duration: 1000 }} on:introstart={addSpace} on:outrostart={removeSpace}>
					<img src="/images/about/about_icon-21.png" alt="" />
					<span>Supply from controlled & responsible sources</span>
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.glance {
		height: 400vh;
		padding: 0 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 0px;
		grid-template-areas: "left right";
	}
	.left {
		grid-area: left;
	}
	.right {
		grid-area: right;
	}
	.title {
		position: sticky;
		transform: translateY(-50%);
		padding: 0 30px;
		font-weight: 900;
		font-size: 90px;
		line-height: 102.4%;
		text-align: center;
		color: #2e3192;
		z-index: 5;
		/* text-shadow: 3px 5px 2px #bbb; */
		text-shadow: 1px 3px 0 #bbb, 1px 13px 5px #ccc;
	}
	.glance-pills {
		position: sticky;
		transform: translateY(-50%);
		transition: height 1s;
		z-index: 2;
	}
	.icon-group {
		margin-left: 12.5%;
		width: 75%;
		height: 100px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		border: 1px solid #ffffff;
		border-radius: 54px;
		filter: drop-shadow(0px 4px 27px rgba(0, 23, 107, 0.25));
	}
	span {
		color: white;
		font-size: calc(0.8 * 24px);
		font-weight: 900;
		text-shadow: 0px 4px 10px #2e3192;
		padding: 0 20px;
	}
	.icon-group > img {
		width: 100px;
	}
	sup {
		vertical-align: text-top;
		font-size: 25px;
	}
</style>
