<picture>
	<source srcset="/images/logos/maxie-logo.webp" />
	<img class="logo" src="/images/logos/maxie-logo.png" alt="logo" />
</picture>

<style>
	picture {
		height: inherit;
	}
	img {
		height: inherit;
		max-width: 100%;
	}
</style>
