<script>
	export let open
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div id="nav-icon1" on:click class:open>
	<span />
	<span />
	<span />
</div>

<style>
	* {
		margin: 0;
		padding: 0;
	}

	/* Icon 1 */

	#nav-icon1 {
		width: calc(60px * 0.6);
		height: calc(45px * 0.6);
		position: relative;
		margin: calc(30px * 0.6) auto;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}

	#nav-icon1 span {
		display: block;
		position: absolute;
		height: calc(9px * 0.6);
		width: 100%;
		background: #b5b7ff;
		border-radius: calc(9px * 0.6);
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
	}

	#nav-icon1 span:nth-child(1) {
		top: calc(0px * 0.6);
	}

	#nav-icon1 span:nth-child(2) {
		top: calc(18px * 0.6);
	}

	#nav-icon1 span:nth-child(3) {
		top: calc(36px * 0.6);
	}

	#nav-icon1.open span:nth-child(1) {
		top: calc(18px * 0.6);
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		transform: rotate(135deg);
	}

	#nav-icon1.open span:nth-child(2) {
		opacity: 0;
		left: calc(-60px * 0.6);
	}

	#nav-icon1.open span:nth-child(3) {
		top: calc(18px * 0.6);
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
</style>
