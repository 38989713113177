<script>
	import { createEventDispatcher, onMount } from "svelte"
	import { fly } from "svelte/transition"
	import Hamburger from "./Header/Hamburger.svelte"
	const dispatch = createEventDispatcher()

	let innerWidth
	let isLoop = false
	let flipVideo

	onMount(() => {
		document.querySelector("main").style.height = "101vh"
		document.querySelector("main").style.overflow = "hidden"
		if (innerWidth && innerWidth < 1150) onEnded()
	})
	function onEnded() {
		isLoop = true
		document.querySelector("main").style.overflow = "visible"
		dispatch("unlock")
		setTimeout(() => flipVideo.play(), 100)
	}
	function skip() {
		document.querySelector("main").style.overflow = "visible"
		dispatch("unlock")
	}
</script>

<svelte:window bind:innerWidth />
{#if innerWidth > 1150}
	{#if !isLoop}
		<div class="skip">
			<Hamburger open={true} on:click={() => onEnded()} />
			<!-- <Hamburger open={true} on:click={() => skip()} /> -->
		</div>
	{/if}
{/if}
<div class="hero">
	<div class="container">
		{#if innerWidth > 1150}
			<video name="cloud" autoplay playsinline muted on:ended={onEnded} class:hidden={isLoop}>
				<source src="/video/cloud.webm" type="video/webm" />
				<h2>Cannot play this video right now</h2>
			</video>
			<video name="flip" loop playsinline muted class:hidden={!isLoop} bind:this={flipVideo}>
				<source src="/video/flip.webm" />
				<h2>Cannot play this video right now</h2>
			</video>
			{#if isLoop}
				<h1 class="text left" in:fly={{ x: -200, duration: 1000 }}>MAXimum <br />Value</h1>
				<h1 class="text right" in:fly={{ x: 200, duration: 1000, delay: 500 }}>Unmatched<br />Softness</h1>
			{/if}
		{:else if innerWidth}
			<div class="row">
				<h1 class="text top">MAXimum <br />Value</h1>
				<!-- <div class="img-cont" style="height: 110vw">
					<img src="./video/flip-poster.webp" alt="flip poster" style="height: 110vw; width: 110vw" />
				</div> -->
				<video name="flip" autoplay playsinline muted loop poster=".\video\flip-poster.webp" bind:this={flipVideo}>
					<source src="/video/flip-square.mp4" />
					<h2>Cannot play this video right now</h2>
				</video>
				<h1 class="text bot">Unmatched<br />Softness</h1>
			</div>
		{/if}
	</div>
</div>

<style>
	.skip {
		position: absolute;
		z-index: 999;
		cursor: pointer;
		right: 28px;
		top: 10px;
	}

	.hidden {
		/* display: none; */
		visibility: hidden;
		opacity: 0;
	}
	.container {
		overflow: hidden;
	}
	video {
		max-height: calc(100vh - 90px);
		min-height: 400px;
		max-width: 100%;
		position: absolute;
		translate: -50%;
	}
	.hero {
		padding: 70px 10px 0px;
		min-height: calc(100vh - 40px);
		display: flex;
		justify-content: center;
		align-content: center;
		/* text-align: center; */
		position: relative;
		z-index: 2;
		overflow: hidden;
	}
	.text {
		position: absolute;
		top: 50%;
		/* translate: 0 -50%; */
		text-align: center;
	}
	.left {
		left: 25%;
		translate: -50%;
	}
	.right {
		left: 75%;
		translate: -50%;
	}

	h1 {
		font-style: normal;
		color: #ffffff;
		font-weight: 900;
		font-size: 7vw;
		text-shadow: 1px 3px 0 #000, 8px 8px 5px #1c1f5c;
		line-height: 100%;
	}

	@media (max-width: 1150px) {
		.hero {
			flex-direction: column;
			justify-content: start;
			align-items: center;
		}
		video {
			width: 110vw;
			max-width: 110vw;
			position: relative;
			left: 50%;
		}
		h1 {
			font-size: 18vw;
			padding: 0;
		}
		.row {
			/* margin-top: -280px; */
			min-height: 85vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-bottom: 40px;
		}
		.text {
			position: static;
			font-size: 12vw;
		}
		.top {
			position: relative;
			top: 25px;
			z-index: 10;
		}
		.bot {
			position: relative;
			bottom: 25px;
		}
	}
</style>
