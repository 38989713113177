<script>
	import { fade, slide } from "svelte/transition"
	import Hamburger from "./Hamburger.svelte"
	import Logo from "../Logo.svelte"
	let scrollY, innerHeight, innerWidth
	let open = false
	export let disabled

	function toggleOpen() {
		if (disabled) return
		open = !open
	}
	function goTo(id) {
		open = false
		document.getElementById(id).scrollIntoView({ behavior: "smooth" })
	}
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<header>
	{#if !disabled}
		<div in:fade={{ delay: 1000 }} class="hamburger">
			<Hamburger {open} on:click={toggleOpen} />
		</div>
	{/if}
	<div class="header">
		{#if open}
			<div class="menu" transition:slide>
				<a href="#top" style="height: 70px">
					<Logo />
				</a>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("products")}>Products</span>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("about")}>About Us</span>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("wheretobuy")}>Where to Buy</span>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("contact")}>Contact</span>
			</div>
		{/if}
	</div>
	<div class="top-logo">
		<Logo />
	</div>
</header>

<style>
	.top-logo {
		position: absolute;
		z-index: 5;
		left: 50%;
		translate: -50%;
		height: 50px;
		top: 8px;
	}
	.header {
		width: 100%;
		max-width: 100%;
		position: fixed;
		top: 0;
		z-index: 15;

		background-color: transparent;
		overflow: hidden;
	}
	.menu {
		padding: 10px;
		height: 100vh;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;

		background-color: #263c8a;
		background-color: #3045b8;
	}

	span {
		color: #b5b7ff;
		font-weight: 700;
		font-size: calc(36px * 0.6);
		text-align: center;
		cursor: pointer;
	}
	span:hover {
		text-decoration: underline;
	}
	.hamburger {
		padding-right: 20px;
		position: fixed;
		top: 0px;
		right: 0;
		z-index: 20;
	}
</style>
