<script>
	import { fly } from "svelte/transition"
	import Hamburger from "./Hamburger.svelte"
	import Logo from "../Logo.svelte"
	let scrollY, innerHeight, innerWidth
	let open = false

	export let disabled

	$: if (!disabled)
		setTimeout(() => {
			open = true
		}, 1000)
	// $: if (scrollY) open = false
	// else open = true

	$: if (!scrollY && !disabled) open = true
	else open = false

	$: inProduct = scrollY > innerHeight * 1 && scrollY < innerHeight * 3

	function toggleOpen() {
		if (disabled) return
		open = !open
	}
	function goTo(id) {
		open = false
		document.getElementById(id).scrollIntoView({ behavior: "smooth" })
	}
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<header>
	<!-- <div class="header" style="background-image: {bg}"> -->
	<div class="header" class:solid={open && inProduct}>
		<a href="#top" class="logo" style="height: 70px">
			<Logo />
		</a>
		<div class="right">
			<!-- <a>{scrollY}</a> -->
			{#if open}
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("products")} transition:fly={{ x: 400, duration: 1000 }}>Products</span>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("about")} transition:fly={{ x: 300, duration: 1000 }}>About Us</span>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("wheretobuy")} transition:fly={{ x: 150, duration: 1000 }}>Where to Buy</span>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<span on:click={() => goTo("contact")} transition:fly={{ x: 75, duration: 1000 }}>Contact</span>
			{/if}
			{#if !disabled}
				<div class="hamburger">
					<Hamburger {open} on:click={toggleOpen} />
				</div>
			{/if}
		</div>
	</div>
</header>

<style>
	.header {
		width: 100%;
		height: 90px;
		padding: 10px;

		display: flex;
		align-items: flex-start;

		position: fixed;
		top: 0;
		z-index: 15;
	}
	.logo {
		margin-right: auto;
	}
	.right {
		display: flex;
		align-items: center;
		gap: 40px;
	}
	span {
		font-weight: 700;
		font-size: calc(36px * 0.6);
		text-align: center;
		color: #b5b7ff;
		padding: 10px 20px;
		border-radius: 100px;
		cursor: pointer;
		user-select: none; /* Standard */
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE 10+ */
	}
	span:hover {
		background-color: #263c8a;
	}
	a:hover {
		background-color: #263c8a;
	}
	a.logo {
		border-radius: 100px;
		padding: 0 10px;
	}
	.hamburger {
		padding-right: 20px;
	}
	.solid {
		background: rgba(46, 49, 146);
	}
</style>
