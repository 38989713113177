import stores from "./locations"
import defaultDistances from "./defaultDistances"
import { DEFAULT_CENTER } from "./MapStore"
import { StoresStore, SelectedStore } from "./MapStore"

export function setMapLocation(location, map) {
	// Set Marker
	let myMarker = new google.maps.Marker({
		position: location,
		title: "Your Location",
		icon: {
			// url: 'http://maps.google.com/mapfiles/ms/micons/man.png'
			// url: 'http://maps.google.com/mapfiles/ms/micons/green.png'
			url: "/images/map/user32.png",
		},
		animation: google.maps.Animation.DROP,
	})
	myMarker.setMap(map)

	// Set Map Bounds (5 closest Stores)
	let bounds = new google.maps.LatLngBounds()
	// find closest stores
	stores.sort((a, b) => {
		return google.maps.geometry.spherical.computeDistanceBetween({ lat: a.lat, lng: a.lng }, location) - google.maps.geometry.spherical.computeDistanceBetween({ lat: b.lat, lng: b.lng }, location)
	})

	// extend bound: 5 closest stores
	stores.slice(0, 5).forEach(store => {
		const { lat, lng } = store
		bounds.extend({ lat, lng })
	})
	// extend bound: my location
	bounds.extend(location)
	map.fitBounds(bounds)

	return myMarker
}

export async function updateSort(location) {
	if (!location) return
	try {
		let distances
		if (location.lat === DEFAULT_CENTER.lat) {
			distances = [...defaultDistances]
			console.log("used default")
		} else {
			const [top, bottom] = await getDistances(location)
			// console.log(top);
			// console.log(bottom)
			distances = [...top.rows[0].elements, ...bottom.rows[0].elements]
			// display travel distance and time
		}

		// console.log(distances)
		for (let i = 0; i < distances.length; i++) {
			// console.log(distances[i]);
			// stores[i].address = distances.destinationAddresses[i];
			stores[i].distance = distances[i].distance?.value || 9999999999999
			stores[i].distanceText = distances[i].distance?.text || "not found"
			stores[i].duration = distances[i].duration?.value || 9999999999999
			stores[i].durationText = distances[i].duration?.text || "not found"
		}

		stores.sort((a, b) => a.distance - b.distance)
		stores.forEach((store, index) => {
			store.marker = index + 1
		})
		StoresStore.update(() => stores)
		SelectedStore.update(() => stores[0].marker)
		// console.log(stores.map(store => store.place_id))
	} catch (error) {
		// console.log(error)
	}
}

function getDistances(location) {
	// console.log("using Distance Maxtrix Service")
	let distanceMatrixService = new google.maps.DistanceMatrixService()

	const top = new Promise((resolve, reject) => {
		const callback = (response, status) => {
			if (status === google.maps.DistanceMatrixStatus.OK && response) {
				// console.log(response);
				resolve(response)
			} else {
				reject(status)
			}
		}
		distanceMatrixService.getDistanceMatrix(
			{
				origins: [location],
				destinations: stores.slice(0, 25).map(store => store.location),
				travelMode: google.maps.TravelMode.DRIVING,
				unitSystem: google.maps.UnitSystem.IMPERIAL,
			},
			callback
		)
	})
	const bottom = new Promise((resolve, reject) => {
		const callback = (response, status) => {
			if (status === google.maps.DistanceMatrixStatus.OK && response) {
				// console.log(response);
				resolve(response)
			} else {
				reject(status)
			}
		}
		// console.log(stores.slice(25).map((store) => store.location));
		distanceMatrixService.getDistanceMatrix(
			{
				origins: [location],
				destinations: stores.slice(25).map(store => store.location),
				travelMode: google.maps.TravelMode.DRIVING,
				unitSystem: google.maps.UnitSystem.IMPERIAL,
			},
			callback
		)
	})
	return Promise.all([top, bottom])
}
