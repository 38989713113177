<script>
	export let ready = false // main.js
	import AddressBar from "./AddressBar.svelte"
	import GetLocation from "./GetLocation.svelte"
	import Map from "./Map.svelte"
	import { setMapLocation, updateSort } from "./mapFunctions"
	import { DEFAULT_CENTER } from "./MapStore"
	import StoreList from "./StoreList.svelte"

	let scrollY, innerHeight, innerWidth
	let canvas, h1, list
	let addressBar
	let triggered = false

	$: canvasY = scrollY - canvas?.offsetTop
	$: h1Bot = h1?.offsetTop + h1?.offsetHeight + scrollY * 0
	$: barBot = list?.offsetTop - 130 + scrollY * 0

	$: if (canvas?.offsetTop != 0 && scrollY > canvas?.offsetTop) {
		if (!triggered) {
			updateSort(DEFAULT_CENTER)
			// setMapLocation(DEFAULT_CENTER, map)
			triggered = true
		}
	}
	// $: if (canvasY < 0) triggered = false

	function clearAddress() {
		addressBar.clearAddress()
	}
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<div class="wheretobuy" id="wheretobuy" bind:this={canvas}>
	<div class="top-left">
		{#if innerWidth > 890}
			<video name="Maxie Elephant Wave" loop autoplay muted>
				<source src="/video/wave.webm" type="video/webm" />
				<source src="/video/wave.mp4" type="video/mp4" />
			</video>
		{:else if innerWidth}
			{#if scrollY >= innerHeight * 5}
				<video name="Maxie Elephant Wave" loop autoplay playsinline muted>
					<source src="/video/wave.mp4" />
				</video>
			{/if}
			<picture>
				<source srcset="/video/wave-poster.webp" type="image/webp" />
				<source srcset="/video/wave-poster.png" type="image/png" />
				<img src="/video/wave-poster.png" alt="wave poster" />
			</picture>
		{/if}
	</div>
	<div class="top-right">
		<div class="find">
			<h1 bind:this={h1}>Find the nearest retailer in your city</h1>
		</div>
	</div>
	{#if ready}
		<div class="bar" style="top:clamp({h1Bot}px, {canvasY * 0.5 + 500}px, {barBot}px)">
			<div class="expand" style="width: min(calc(50% + 50px), calc({canvasY * 0.8}px + 25%))">
				<AddressBar bind:this={addressBar} />
			</div>
			<GetLocation on:gotGeo={clearAddress} />
		</div>
		<div class="bot-left" bind:this={list}>
			<StoreList />
		</div>
		<div class="bot-right">
			<Map />
		</div>
	{/if}
</div>

<style>
	.wheretobuy {
		/* height: 200vh; */
		padding: 0 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 50px;
		grid-row-gap: 0px;
		background-color: #ececff;
		position: relative;
		overflow-x: hidden;
	}

	.top-left {
		/* margin-right: -50px; */
		grid-area: 1 / 1 / 2 / 2;
		height: 100vh;
		position: relative;
		z-index: 10;
		/* padding: 50px 0; */
		padding: 50px 0 0;
		display: flex;
		/* justify-content: center; */
		justify-content: start;
		align-items: center;
		flex-direction: column;
	}
	video {
		max-width: 80%;
	}
	.top-right {
		grid-area: 1 / 2 / 2 / 3;
		/* height: 100vh; */
		justify-content: center;
		align-items: center;
		display: flex;
		position: relative;
		z-index: 10;
	}
	.bot-left {
		grid-area: 2 / 1 / 3 / 2;
		height: calc(90vh - 130px - 30px);
		position: relative;
		z-index: 10;
		opacity: 0;
	}
	.bot-right {
		grid-area: 2 / 1 / 3 / 3;
		height: calc(90vh - 130px - 30px);
		position: relative;
		z-index: 10;
	}

	.bar {
		position: absolute;
		margin-left: -30px;
		padding: 30px 0;
		height: 130px;
		display: flex;
		justify-content: center;
		width: 100%;
		z-index: 15;
	}
	h1 {
		max-width: 450px;
		font-weight: 900;
		font-size: 60px;
		color: var(--indigo);
		text-align: center;
		line-height: 61px;
		padding-bottom: 50px;
		z-index: 2;
		position: relative;
		/* text-shadow: 1px 3px 0 #bbb, 1px 13px 5px #ccc; */
	}

	@media (max-width: 890px) {
		.wheretobuy {
			display: block;
			padding: 0 15px;
			z-index: 10;
		}
		video {
			max-width: 100%;
			position: absolute;
		}
		.top-left {
			height: 100vw;
			margin: 0 0 20px;
			padding: 0;
		}
		.top-left img {
			width: 100%;
		}
		.bar {
			position: relative;
			top: 0 !important;
			z-index: 10;
			display: block;
			margin: auto;
			height: auto;
		}
		.expand {
			/* margin: 0; */
			/* padding: 0 15px; */
			width: 100% !important;
		}
		.bot-left {
			height: 0vh;
			/* min-height: 33vh; */
			/* margin-bottom: 50px; */
			/* overflow: hidden; */
		}
		.bot-right {
			height: 66vh;
			min-height: 66vh;
			padding-bottom: 50px;
		}
		h1 {
			margin: 20px 0;
			padding: 0;
		}
	}
</style>
