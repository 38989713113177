<script>
	import StampsStatic from "./StampsStatic.svelte"

	let scrollY, innerHeight, innerWidth, canvas, canvasHeight
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<div class="green-canvas" bind:this={canvas} bind:clientHeight={canvasHeight}>
	<div class="title">MAXIE<sup>Ⓡ</sup><br />Cares</div>
	<div class="text">
		<p>
			Outside of our mission to keep you comfortable indoors, Maxie believes that product sustainability is the cornerstone for any company’s credibility. Maxie has adopted a
			<br /><strong>low-carbon, green supply chain methodology.</strong>
			<br /><br />
			With sustainability in mind, Maxie takes pride in caring not only for our beloved customers, but also for our planet. 🌎
		</p>
	</div>
	<div class="stamps-box" style="height: {innerWidth}px">
		{#if scrollY > innerHeight * 3}
			<StampsStatic />
		{/if}
	</div>
</div>

<style>
	.green-canvas {
		background: linear-gradient(to right, #85c196, #16a491);
		color: white;
		overflow: hidden;
		/* padding-top: 10vh; */
	}

	.text {
		text-align: center;
		font-size: 16px;
		margin-bottom: 50px;
		padding: 0 30px;
		max-width: 600px;
		margin: 50px auto;
		z-index: 5;
		position: relative;
		color: #16a491;
		/* text-shadow: 1px 3px 0 #bbb, 1px 13px 5px #ccc; */
	}

	.title {
		padding: 50px 30px 0px;
		font-weight: 900;
		font-size: 15vw;
		line-height: 102.4%;
		text-align: center;
		color: green;
		text-shadow: 1px 3px 0 #bbb, 1px 13px 5px #ccc;
		position: relative;
		z-index: 5;
		/* top: -100px; */
	}
	.stamps-box {
		position: relative;
		z-index: 5;
		/* overflow: hidden; */
	}
	strong {
		font-weight: 900;
	}
	sup {
		vertical-align: text-top;
		font-size: 25px;
	}
</style>
