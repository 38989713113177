<script>
	import Logo from "./Logo.svelte"
	function goTo(id) {
		document.getElementById(id).scrollIntoView({ behavior: "smooth" })
	}
</script>

<footer>
	<div class="footer" id="footer">
		<div class="grid">
			<div class="left">
				<a href="#top">
					<Logo />
				</a>
			</div>
			<div class="right">
				<div class="links">
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<span on:click={() => goTo("about")}>About Us</span>
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<span on:click={() => goTo("products")}>Products</span>
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<span on:click={() => goTo("wheretobuy")}>Where to Buy</span>
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<span on:click={() => goTo("contact")}>Contact</span>
				</div>

				<div class="social">
					<a href="https://www.facebook.com/Maxiepaper" target="_blank" rel="noreferrer">
						<img src="/images/social/facebook_icon.png" alt="facebook" />
					</a>
					<a href="https://www.instagram.com/maxiepaper/" target="_blank" rel="noreferrer">
						<img src="/images/social/instagram_icon.png" alt="instagram" />
					</a>
					<h2>@maxiepaper</h2>
				</div>
			</div>
		</div>
		<div class="bottom">© Maxie Paper 2023 - BuiltByBILLT</div>
	</div>
</footer>

<style>
	.footer {
		/* min-height: 33vh; */
		background: #2e3192;
		font-family: "Nunito";
		font-style: normal;
		color: #ffffff;
		z-index: 2;
		position: relative;
	}
	.grid {
		padding: 100px 50px 130px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 0px;
	}
	.left {
		grid-area: 1 / 1 / 2 / 2;
	}
	.left a {
		display: block;
		width: 20vw;
	}
	.right {
		grid-area: 1 / 2 / 2 / 3;
		position: relative;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.links {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	span {
		color: #ffffff;
		font-weight: 700;
		font-size: clamp(6px, 2vw, 24px);
		cursor: pointer;
	}
	span:hover {
		text-decoration: underline;
	}
	.social {
		text-align: center;
	}
	.social img {
		margin: 0 10px;
	}
	h2 {
		font-weight: 900;
		font-size: 2vw;
	}
	.bottom {
		font-weight: 600;
		font-size: 18px;
		text-align: center;
		background: #2e3192;

		position: absolute;
		bottom: 0;
		left: 50%;
		translate: -50%;
	}

	@media (max-width: 890px) {
		.grid {
			display: block;
			padding-top: 50px;
		}
		.left {
			text-align: center;
		}
		.left a {
			width: 100%;
		}
		img {
			width: 100%;
			max-width: 600px;
		}
		.right {
			flex-direction: column;
		}
		.links {
			text-align: center;
			margin: 30px 0;
		}
		.social {
			margin: 30px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 20px;
		}
		.social h2 {
			width: 100%;
			font-size: 36px;
		}
		.social a {
			width: 60px;
		}
		.social img {
			margin: 0;
		}
		.bottom {
			padding-bottom: 10px;
			font-size: 12px;
			width: 100%;
		}
		.links span {
			margin: 15px 0;
			font-size: 22px;
		}
		span {
			font-size: 20px;
		}
	}
</style>
