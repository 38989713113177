<script>
	let messageBox
	// let scrollY, innerHeight, innerWidth
	// let circle = true

	// $: scrollMid = scrollY + innerHeight / 3
	// $: circle = !(messageBox?.offsetTop !== 0 && scrollMid > messageBox?.offsetTop)
</script>

<!-- <svelte:window bind:scrollY bind:innerHeight bind:innerWidth /> -->

<div class="contact" id="contact">
	<div class="right" bind:this={messageBox}>
		<h1>Get in touch</h1>
		<h2>we’d love to hear from you</h2>
		<div class="social">
			<div class="btn fb">
				<img src="/images/social/fb_icon.png" alt="facebook" />
				<a href="https://www.facebook.com/maxiepaper" target="_blank" rel="noreferrer"> {innerWidth < 550 ? "Message us!" : "Message us on Facebook!"}</a>
			</div>
			<div class="btn ig">
				<img src="/images/social/ig_icon.png" alt="instagram" />
				<a href="https://www.instagram.com/Maxiepaper" target="_blank" rel="noreferrer"> {innerWidth < 550 ? "Message us!" : "Message us on Instagram!"}</a>
			</div>
		</div>
	</div>
	<div class="left">
		<div class="news">
			<form
				action="https://bulksaversinc.us6.list-manage.com/subscribe/post?u=ce2c14f3d66b9c10b4ce9a429&id=be20d6484b"
				method="post"
				id="mc-embedded-subscribe-form"
				name="mc-embedded-subscribe-form"
				class="validate"
				target="_blank"
				noValidate>
				<h3>Sign up to receive news, updates, and offers!</h3>
				<input placeholder="Email Address" type="email" name="EMAIL" id="mce-EMAIL" />
				<input placeholder="First Name" type="text" name="FNAME" id="mce-FNAME" />
				<input placeholder="Last Name" type="text" name="LNAME" id="mce-LNAME" />
				<div class="bot">
					<input class="bot-input" type="text" name="b_ce2c14f3d66b9c10b4ce9a429_be20d6484b" tabIndex={-1} defaultValue />
				</div>
				<button>Sign Up!</button>
			</form>
		</div>
	</div>
</div>

<style>
	.contact {
		min-height: 100vh;
		padding: 50px 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 0px;
		background-color: #ececff;
		font-family: "Nunito";
		grid-template-areas: "left right";
	}
	.left {
		grid-area: left;
		/* padding: 0 30px; */
		position: relative;
		z-index: 10;
	}
	.right {
		grid-area: right;
		position: relative;
		z-index: 10;
		padding: 50px 30px 0;

		letter-spacing: 0em;
		text-align: center;
		color: var(--indigo);
	}
	h1 {
		font-size: 74px;
		font-weight: 900;

		line-height: 1;
		margin-bottom: 20px;
		/* text-shadow: 3px 5px 2px #bbb; */
	}
	h2 {
		font-size: 36px;
		font-weight: 900;
		/* text-shadow: 3px 5px 2px #bbb; */
	}
	h3 {
		text-align: center;
		margin-bottom: 50px;
		text-shadow: 0px 4px 10px #2e3192;
		color: white;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.news {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 50%;
		padding: 30px;
		background-color: var(--indigo);
		border-radius: 20px;
		margin: 0 auto;
		max-width: 600px;
	}
	.social {
		margin: 80px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	input,
	.btn,
	button {
		width: 100%;
		max-width: 400px;
		height: 70px;
		box-sizing: border-box;
		border-radius: 50px;
		font-weight: 600;
		font-size: 18px;
		box-shadow: 0px 4px 27px rgba(0, 23, 107, 0.25);
		margin-bottom: 20px;
		font-family: "Nunito";
	}
	input {
		padding: 0 40px;
	}
	button {
		color: var(--indigo);
		font-weight: 900;
		text-align: center;
		background-color: #ddd;
		cursor: pointer;
		padding-left: 0;
		letter-spacing: 0em;
		text-align: center;
	}
	button:hover,
	.btn:hover {
		font-size: 23px;
	}
	.btn {
		width: 650px;
		color: #fff;
		transition: width 1s;
		overflow: hidden;
		display: flex;
		align-items: center;
		font-size: 22px;
		font-weight: 900;
		letter-spacing: 0em;
		text-align: center;
		padding-left: 17px;
		cursor: pointer;
	}
	/* .circle.ig,
	.circle.fb {
		width: 80px !important;
		min-width: 80px;
	} */
	a {
		overflow: hidden;
		white-space: nowrap;
		margin: 0 auto;
		padding: 0 20px;
		color: #fff;
	}
	.ig {
		background: linear-gradient(190deg, #8942b0 0%, #e34859 55.73%, #fcad4d 100%);
	}
	.fb {
		background: #2e3192;
	}

	.bot {
		width: 1%;
		height: 1%;
		overflow: hidden;
		display: block;
		position: absolute;
	}
	.bot-input {
		left: 50px;
		position: absolute;
		display: block;
	}
	.news {
		padding: 50px;
	}

	@media (max-width: 890px) {
		.contact {
			display: block;
			padding: 50px 15px;
		}
		.left {
			padding: 0;
			margin-bottom: 200px;
		}
		.btn {
			width: 100%;
		}
		h1 {
			font-size: 64px;
			margin-bottom: 40px;
		}
		h2 {
			font-size: 32px;
			line-height: 1;
		}
		.social {
			margin: 80px 0;
		}
	}
</style>
