<script>
	let blueHeight
	let innerWidth
</script>

<svelte:window bind:innerWidth />
<div class="blue" bind:clientHeight={blueHeight}>
	<div class="container" style="height:{blueHeight}px">
		{#if innerWidth > 1150}
			<div class="hero-left" />
			<div class="hero-right" />
			<div class="product" />
		{/if}
		<div class="ellipse-max" />
		<div class="ellipse-roll" />
		<div class="ellipse-glance" id="about" />
	</div>
	<slot />
</div>

<style>
	.blue {
		width: 100%;
		background: linear-gradient(105.04deg, #263c8a -10.04%, #3045b8 91.26%);
	}
	.container {
		width: 100%;
		overflow: hidden;
		position: absolute;
		z-index: 1;
	}

	.hero-left {
		position: absolute;
		width: calc(908 / 1488 * 100vmax);
		height: calc(907 / 1488 * 100vmax);
		height: calc(707 / 1488 * 100vmax);
		left: calc(-254 / 1488 * 100vmax);
		top: calc(-97 / 1488 * 100vmax);

		background: #263c8a;
		background: #0066ff;
		background: #00b2ff;

		mix-blend-mode: screen;
		filter: blur(300px);
	}
	.hero-right {
		position: absolute;
		width: calc(690 / 1488 * 100vmax);
		height: calc(690 / 1488 * 100vmax);
		height: calc(290 / 1488 * 100vmax);
		left: calc(1031 / 1488 * 100vmax);
		top: calc(-32 / 1488 * 100vmax);

		/* background: #2f343d; */
		background: #00b2ff;
		background: #0066ff;
		background: #263c8a;

		mix-blend-mode: screen;
		filter: blur(300px);
	}
	.product {
		position: absolute;
		width: calc(1298 / 1488 * 100vmax);
		height: calc(392 / 1488 * 100vmax);
		left: calc(82 / 1488 * 100vmax);
		top: calc(1781 / 1488 * 100vmax);

		background: #00b2ff;
		mix-blend-mode: screen;
		filter: blur(300px);
	}
	.ellipse-max {
		position: absolute;
		width: 50vw;
		height: 60vh;
		left: 50%;
		top: 320vh;
		transform: translateX(-50%);

		background: #00b2ff;
		mix-blend-mode: screen;
		filter: blur(300px);
	}
	.ellipse-roll {
		position: absolute;
		width: calc(908 / 1488 * 100vmax);
		height: calc(907 / 1488 * 100vmax);
		left: calc(-254 / 1488 * 100vmax);
		top: calc(420vh);

		background: #0066ff;
		mix-blend-mode: screen;
		filter: blur(300px);
	}
	.ellipse-glance {
		position: absolute;
		width: calc(908 / 1488 * 100vmax);
		height: calc(907 / 1488 * 100vmax);
		top: calc(650vh);
		left: 50%;
		transform: translateX(-50%);

		background: #00b2ff;
		mix-blend-mode: screen;
		filter: blur(300px);
	}
	@media (max-width: 1150px) {
		.blue {
			background: #383b98;
			/* background: #3a3393; */
			/* background: #393594; */
		}
		.hero-left {
			visibility: hidden;
		}
		.hero-right {
			visibility: hidden;
		}
	}
</style>
