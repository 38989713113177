<script>
	import { onMount } from "svelte"
	import Swiper from "swiper"
	import { Navigation, Pagination } from "swiper/modules"

	import "swiper/css"
	import "swiper/css/navigation"
	import "swiper/css/pagination"

	onMount(() => {
		const swiper = new Swiper(".mySwiper", {
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
			},
			modules: [Navigation, Pagination],
		})
	})
</script>

<div class="swiper mySwiper">
	<div class="swiper-wrapper">
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Angle-small.webp 452w, /images/products/tp/Angle-medium.webp 905w, /images/products/tp/Angle-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Angle-small.png 452w, /images/products/tp/Angle-medium.png 905w, /images/products/tp/Angle-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Angle-small.png" alt="Maxie Toilet Paper Angle" />
			</picture>
		</div>
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Front-small.webp 452w, /images/products/tp/Front-medium.webp 905w, /images/products/tp/Front-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Front-small.png 452w, /images/products/tp/Front-medium.png 905w, /images/products/tp/Front-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Front-small.png" alt="Maxie Toilet Paper Front" />
			</picture>
		</div>
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Back-small.webp 452w, /images/products/tp/Back-medium.webp 905w, /images/products/tp/Back-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Back-small.png 452w, /images/products/tp/Back-medium.png 905w, /images/products/tp/Back-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Back-small.png" alt="Maxie Toilet Paper Back" />
			</picture>
		</div>
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Stack-Angle-small.webp 452w, /images/products/tp/Stack-Angle-medium.webp 905w, /images/products/tp/Stack-Angle-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Stack-Angle-small.png 452w, /images/products/tp/Stack-Angle-medium.png 905w, /images/products/tp/Stack-Angle-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Stack-Angle-small.png" alt="Maxie Toilet Paper Stack-Angle" />
			</picture>
		</div>
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Stack-Front-small.webp 452w, /images/products/tp/Stack-Front-medium.webp 905w, /images/products/tp/Stack-Front-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Stack-Front-small.png 452w, /images/products/tp/Stack-Front-medium.png 905w, /images/products/tp/Stack-Front-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Stack-Front-small.png" alt="Maxie Toilet Paper Stack-Front" />
			</picture>
		</div>
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Stack-Side-small.webp 452w, /images/products/tp/Stack-Side-medium.webp 905w, /images/products/tp/Stack-Side-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Stack-Side-small.png 452w, /images/products/tp/Stack-Side-medium.png 905w, /images/products/tp/Stack-Side-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Stack-Side-small.png" alt="Maxie Toilet Paper Stack-Side" />
			</picture>
		</div>
		<div class="swiper-slide">
			<picture>
				<source
					srcset="/images/products/tp/Stack-Side-2-small.webp 452w, /images/products/tp/Stack-Side-2-medium.webp 905w, /images/products/tp/Stack-Side-2-large.webp 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/webp" />
				<source
					srcset="/images/products/tp/Stack-Side-2-small.png 452w, /images/products/tp/Stack-Side-2-medium.png 905w, /images/products/tp/Stack-Side-2-large.png 1358w"
					sizes="(max-width: 600px) 452px, (max-width: 1200px) 905px, 1358px"
					type="image/png" />
				<img src="/images/products/tp/Stack-Side-2-small.png" alt="Maxie Toilet Paper Stack-Side-2" />
			</picture>
		</div>
	</div>
	<div class="swiper-button-next" />
	<div class="swiper-button-prev" />
	<div class="swiper-pagination" />
</div>

<style>
	img {
		width: 100%;
		object-fit: contain;
		max-height: 67vh;
	}
	.swiper {
		width: 100%;
		max-width: 100%;
		min-width: 0;
		min-height: 0;
		padding-bottom: 10px;
	}
	.swiper-button-next,
	.swiper-button-prev {
		color: white;
		scale: 0.5;
		width: 100px;
		height: 100px;
		top: 50%;
		translate: 0 -50%;
		transition: 0.2s;
		border-radius: 50px;
		place-items: center;
	}
	.swiper-button-prev {
		left: -30px;
	}
	.swiper-button-next {
		right: -30px;
	}
	.swiper-button-next:hover,
	.swiper-button-prev:hover {
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	}

	:global(.swiper-button-disabled) {
		opacity: 0 !important;
	}

	:global(.swiper-pagination-bullet-active) {
		background: #808ad9 !important;
	}
</style>
