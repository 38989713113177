export default [
	{
		distance: {
			text: "0.2 mi",
			value: 293,
		},
		duration: {
			text: "2 mins",
			value: 92,
		},
		status: "OK",
	},
	{
		distance: {
			text: "2.2 mi",
			value: 3570,
		},
		duration: {
			text: "8 mins",
			value: 450,
		},
		status: "OK",
	},
	{
		distance: {
			text: "2.3 mi",
			value: 3745,
		},
		duration: {
			text: "10 mins",
			value: 597,
		},
		status: "OK",
	},
	{
		distance: {
			text: "2.4 mi",
			value: 3903,
		},
		duration: {
			text: "9 mins",
			value: 537,
		},
		status: "OK",
	},
	{
		distance: {
			text: "3.0 mi",
			value: 4768,
		},
		duration: {
			text: "10 mins",
			value: 579,
		},
		status: "OK",
	},
	{
		distance: {
			text: "3.0 mi",
			value: 4830,
		},
		duration: {
			text: "10 mins",
			value: 587,
		},
		status: "OK",
	},
	{
		distance: {
			text: "3.1 mi",
			value: 4995,
		},
		duration: {
			text: "9 mins",
			value: 556,
		},
		status: "OK",
	},
	{
		distance: {
			text: "3.2 mi",
			value: 5103,
		},
		duration: {
			text: "10 mins",
			value: 605,
		},
		status: "OK",
	},
	{
		distance: {
			text: "3.6 mi",
			value: 5782,
		},
		duration: {
			text: "13 mins",
			value: 758,
		},
		status: "OK",
	},
	{
		distance: {
			text: "4.5 mi",
			value: 7237,
		},
		duration: {
			text: "11 mins",
			value: 665,
		},
		status: "OK",
	},
	{
		distance: {
			text: "5.8 mi",
			value: 9371,
		},
		duration: {
			text: "14 mins",
			value: 856,
		},
		status: "OK",
	},
	{
		distance: {
			text: "6.1 mi",
			value: 9799,
		},
		duration: {
			text: "21 mins",
			value: 1275,
		},
		status: "OK",
	},
	{
		distance: {
			text: "6.4 mi",
			value: 10244,
		},
		duration: {
			text: "19 mins",
			value: 1129,
		},
		status: "OK",
	},
	{
		distance: {
			text: "9.2 mi",
			value: 14741,
		},
		duration: {
			text: "20 mins",
			value: 1174,
		},
		status: "OK",
	},
	{
		distance: {
			text: "9.8 mi",
			value: 15736,
		},
		duration: {
			text: "17 mins",
			value: 1018,
		},
		status: "OK",
	},
	{
		distance: {
			text: "9.8 mi",
			value: 15775,
		},
		duration: {
			text: "17 mins",
			value: 1045,
		},
		status: "OK",
	},
	{
		distance: {
			text: "9.7 mi",
			value: 15677,
		},
		duration: {
			text: "29 mins",
			value: 1711,
		},
		status: "OK",
	},
	{
		distance: {
			text: "10.0 mi",
			value: 16090,
		},
		duration: {
			text: "28 mins",
			value: 1673,
		},
		status: "OK",
	},
	{
		distance: {
			text: "10.9 mi",
			value: 17551,
		},
		duration: {
			text: "19 mins",
			value: 1113,
		},
		status: "OK",
	},
	{
		distance: {
			text: "8.0 mi",
			value: 12934,
		},
		duration: {
			text: "25 mins",
			value: 1485,
		},
		status: "OK",
	},
	{
		distance: {
			text: "11.1 mi",
			value: 17854,
		},
		duration: {
			text: "22 mins",
			value: 1348,
		},
		status: "OK",
	},
	{
		distance: {
			text: "11.3 mi",
			value: 18176,
		},
		duration: {
			text: "27 mins",
			value: 1643,
		},
		status: "OK",
	},
	{
		distance: {
			text: "13.2 mi",
			value: 21313,
		},
		duration: {
			text: "19 mins",
			value: 1166,
		},
		status: "OK",
	},
	{
		distance: {
			text: "15.0 mi",
			value: 24158,
		},
		duration: {
			text: "23 mins",
			value: 1403,
		},
		status: "OK",
	},
	{
		distance: {
			text: "15.2 mi",
			value: 24422,
		},
		duration: {
			text: "34 mins",
			value: 2032,
		},
		status: "OK",
	},
	{
		distance: {
			text: "17.3 mi",
			value: 27799,
		},
		duration: {
			text: "26 mins",
			value: 1580,
		},
		status: "OK",
	},
	{
		distance: {
			text: "18.1 mi",
			value: 29151,
		},
		duration: {
			text: "24 mins",
			value: 1468,
		},
		status: "OK",
	},
	{
		distance: {
			text: "17.7 mi",
			value: 28479,
		},
		duration: {
			text: "23 mins",
			value: 1380,
		},
		status: "OK",
	},
	{
		distance: {
			text: "17.8 mi",
			value: 28618,
		},
		duration: {
			text: "26 mins",
			value: 1530,
		},
		status: "OK",
	},
	{
		distance: {
			text: "19.3 mi",
			value: 31067,
		},
		duration: {
			text: "30 mins",
			value: 1796,
		},
		status: "OK",
	},
	{
		distance: {
			text: "24.4 mi",
			value: 39281,
		},
		duration: {
			text: "39 mins",
			value: 2357,
		},
		status: "OK",
	},
	{
		distance: {
			text: "27.7 mi",
			value: 44591,
		},
		duration: {
			text: "38 mins",
			value: 2305,
		},
		status: "OK",
	},
	{
		distance: {
			text: "28.0 mi",
			value: 45000,
		},
		duration: {
			text: "42 mins",
			value: 2512,
		},
		status: "OK",
	},
	{
		distance: {
			text: "28.9 mi",
			value: 46438,
		},
		duration: {
			text: "43 mins",
			value: 2582,
		},
		status: "OK",
	},
	{
		distance: {
			text: "30.6 mi",
			value: 49256,
		},
		duration: {
			text: "43 mins",
			value: 2593,
		},
		status: "OK",
	},
	{
		distance: {
			text: "38.4 mi",
			value: 61763,
		},
		duration: {
			text: "45 mins",
			value: 2690,
		},
		status: "OK",
	},
	{
		distance: {
			text: "40.0 mi",
			value: 64333,
		},
		duration: {
			text: "51 mins",
			value: 3041,
		},
		status: "OK",
	},
	{
		distance: {
			text: "71.5 mi",
			value: 115098,
		},
		duration: {
			text: "1 hour 21 mins",
			value: 4868,
		},
		status: "OK",
	},
]
