<script>
	let scrollY, innerHeight, innerWidth, canvas
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<div class="glance" bind:this={canvas}>
	<div class="title">About<br />MAXIE<sup>Ⓡ</sup></div>
	<div class="glance-pills">
		<div class="icon-group">
			<img src="/images/about/about_icon-17.png" alt="" />
			<span>No added Optical Brightener or Fluorescent Additives</span>
		</div>
		<div class="icon-group">
			<img src="/images/about/about_icon-18.png" alt="" />
			<span>Ultra Soft and Strong</span>
		</div>
		<div class="icon-group">
			<img src="/images/about/about_icon-19.png" alt="" />
			<span>Ultra High Temp Sterilized</span>
		</div>
		<div class="icon-group">
			<img src="/images/about/about_icon-20.png" alt="" />
			<span>100% Virgin Pulp</span>
		</div>
		<div class="icon-group">
			<img src="/images/about/about_icon-21.png" alt="" />
			<span>Supply from controlled & responsible sources</span>
		</div>
	</div>
</div>

<style>
	.glance {
		/* height: 300vh; */
		padding: 0 30px 25vh;
		position: relative;
	}
	.title {
		margin-bottom: 50px;
		font-weight: 900;
		font-size: 15vw;
		line-height: 102.4%;
		text-align: center;
		color: #2e3192;
		text-shadow: 1px 3px 0 #bbb, 1px 13px 5px #ccc;
		position: relative;
		z-index: 5;
	}
	.glance-pills {
		max-width: 600px;
		margin: 0 auto;
		position: relative;
		z-index: 5;
	}
	.icon-group {
		height: 100px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		/* border: 1px solid #ffffff; */
		/* border: 1px solid #2e3192; */
		border-radius: 54px;
		/* filter: drop-shadow(0px 4px 27px rgba(0, 23, 107, 0.25)); */
	}
	span {
		/* color: white; */
		color: #2e3192;
		font-size: 16px;
		font-weight: 900;
		/* text-shadow: 0px 4px 10px #2e3192; */
		padding: 0 0 0 20px;
	}
	.icon-group > img {
		width: 100px;
	}
	sup {
		vertical-align: text-top;
		font-size: 25px;
	}
</style>
