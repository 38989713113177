<script>
	let scrollY, innerHeight, innerWidth, canvas
	$: maxRotate = innerWidth < 1150 ? 20 : 10
	// $: maxRotate = 50

	$: canvasY = scrollY - canvas?.offsetTop + innerHeight
	$: rotateX = innerWidth > 890 ? Math.min(Math.max((-maxRotate / innerHeight) * canvasY + maxRotate - 1, 0), maxRotate) : 0
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />
<div class="maximum" bind:this={canvas}>
	<div class="container" style="transform: rotateX({rotateX}deg)">
		<svg width="514" height="151" preserveAspectRatio="none" viewBox="0 0 514 151" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M60.9395 31L46.9395 56H32.9395L18.9395 31V70H0.939453V-4.76837e-06H19.9395L39.9395 37L59.9395 -4.76837e-06H78.9395V70H60.9395V31ZM103.479 70H83.9785L110.479 -4.76837e-06H130.479L156.979 70H137.479L132.979 57.5H107.979L103.479 70ZM112.479 44.5H128.479L120.479 21L112.479 44.5ZM202.475 -4.76837e-06H222.475L199.975 35L222.975 70H202.975L188.975 48L174.975 70H154.975L177.975 35L155.475 -4.76837e-06H175.375L188.975 22L202.475 -4.76837e-06ZM260.99 70H224.99V56H233.99V14H224.99V-4.76837e-06H260.99V14H251.99V56H260.99V70ZM329.982 31L315.982 56H301.982L287.982 31V70H269.982V-4.76837e-06H288.982L308.982 37L328.982 -4.76837e-06H347.982V70H329.982V31ZM405.021 -4.76837e-06H423.021V51C423.021 58 421.421 63.1 418.221 66.3C415.088 69.4333 410.021 71 403.021 71H380.021C373.021 71 367.921 69.4333 364.721 66.3C361.588 63.1 360.021 58 360.021 51V-4.76837e-06H378.021V51C378.021 55 380.021 57 384.021 57H399.021C403.021 57 405.021 55 405.021 51V-4.76837e-06ZM495.021 31L481.021 56H467.021L453.021 31V70H435.021V-4.76837e-06H454.021L474.021 37L494.021 -4.76837e-06H513.021V70H495.021V31ZM125.02 129L142.02 80H161.52L135.02 150H115.02L88.5195 80H108.02L125.02 129ZM171.984 150H152.484L178.984 80H198.984L225.484 150H205.984L201.484 137.5H176.484L171.984 150ZM180.984 124.5H196.984L188.984 101L180.984 124.5ZM248.48 136H284.98V150H230.48V80H248.48V136ZM337.004 80H355.004V131C355.004 138 353.404 143.1 350.204 146.3C347.071 149.433 342.004 151 335.004 151H312.004C305.004 151 299.904 149.433 296.704 146.3C293.571 143.1 292.004 138 292.004 131V80H310.004V131C310.004 135 312.004 137 316.004 137H331.004C335.004 137 337.004 135 337.004 131V80ZM422.504 150H367.004V80H422.504V94H385.004V107.5H414.504V121.5H385.004V136H422.504V150Z"
				fill="white" />
		</svg>
	</div>
	<div class="row">
		<div class="col">
			<h4>ultra soft comfort</h4>
			<p>
				Premium virgin pulp that offers support and comfortability from all angles. Our sheets are cut to square perfection giving it an unmatched softness and consistency full of gentle protection.
			</p>
		</div>
		<div class="col">
			<h4>utmost durability</h4>
			<p>
				Relentless flexibility with each sheet to ensure quality performance. Our fortified strength guarantees you will never need to worry about rips and tears in places where it’s most crucial.
			</p>
		</div>
		<div class="col">
			<h4>feel value</h4>
			<p>
				Less is More. Thicker 2 ply sheets to cover all areas you need at a fraction of what you typically use. At Maxie, we strive to bridge the gap between superior quality and everyday
				affordability.
			</p>
		</div>
	</div>
</div>

<style>
	.maximum {
		/* height: 100vh; */
		color: white;
		margin-top: 30px;
		padding: 30px;
		perspective: 100px;
		overflow-x: hidden;
		position: relative;
		z-index: 2;
	}
	.container {
		width: 100%;
		height: 70vmin;
		position: relative;
		transform-origin: center;
		transform-style: preserve-3d;
	}
	svg {
		width: 100%;
		height: 100%;
	}
	.row {
		display: flex;
		padding: 50px 0;
		gap: 40px;
	}
	.col {
		color: white;
		width: 33%;
		text-align: center;
	}
	h4 {
		font-size: 24px;
		padding-bottom: 10px;
	}

	@media (max-width: 890px) {
		.container {
			margin: 60px 0;
			height: 60vmin;
		}
		.row {
			flex-direction: column;
			align-items: center;
			gap: 60px;
		}
		.col {
			width: 100%;
			max-width: 500px;
		}
	}
</style>
