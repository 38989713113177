<script>
</script>

<div class="stamps">
	<img class="CFCC" src="/images/pledge/CFCC.svg" alt="CFCC" />
	<img class="PEFC" src="/images/pledge/PEFC.svg" alt="PEFC" />
	<img class="FSC" src="/images/pledge/FSC.svg" alt="FSC" />
	<img class="FFA" src="/images/pledge/FFA.svg" alt="FFA" />
</div>

<style>
	.stamps {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	img {
		position: relative;
		animation-name: stamp;
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
		-webkit-animation-name: stamp;
		-webkit-animation-duration: 0.3s;
		-webkit-animation-fill-mode: forwards;
		opacity: 0;
	}
	.CFCC {
		width: 46%;
		top: 4px;
		left: -12px;
		transform: rotate(-10deg) scale(1);
		animation-delay: 0s;
	}
	.PEFC {
		width: 40%;
		top: 10px;
		left: -14px;
		transform: rotate(4deg) scale(1);
		animation-delay: 0.2s;
	}
	.FSC {
		width: 40%;
		top: -45px;
		left: 48px;
		transform: rotate(-3deg) scale(1);
		animation-delay: 0.5s;
	}
	.FFA {
		width: 48%;
		top: -48px;
		left: 18px;
		transform: rotate(4deg) scale(1);
		animation-delay: 0.7s;
	}

	@keyframes stamp {
		0% {
			opacity: 0;
		}
		10% {
			opacity: 0.5;
			transform-origin: 50% 50%;
			transform: rotate(-2deg) scale(3);
			transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
		}
		100% {
			opacity: 1;
		}
	}
</style>
