import App from "./App.svelte"

const app = new App({
	target: document.body,
	props: {
		name: "Billy",
		ready: false,
	},
})

window.initMap = function ready() {
	app.$set({ ready: true })
	// console.log("ready")
}

export default app
